import React, { useState } from 'react';
import PropTypes from 'prop-types';
import marked from 'marked';
import { Col, Row } from 'react-simple-flex-grid';

import Message from '../../global/Message';
import Container from '../../global/Container';
import FormInput from '../../global/FormInput';
import TextArea from '../../global/TextArea';
import { ContentContainer, FormContainer, RichText } from './styled';
import { Heading1 } from '../../global/Typography';

import { formatList, getText } from './helpers';
import Button from '../../global/Button';
import { validateRequestDemoForm } from '../../../utils/validators';
import { postSlackMessage } from '../../../utils/slack';

const initialValues = {
    firstName: '',
    lastName: '',
    businessEmail: '',
    companyName: '',
    phoneNumber: '',
    country: '',
    interest: '',
};

const RequestDemo = ({ data }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [errors, setErrors] = useState({});
    const [values, setValues] = useState(initialValues);
    const [successFormSubmission, setSuccessFormSubmission] = useState(false);
    const [errorFormSubmission, setErrorFormSubmission] = useState(false);

    function handleOnChange(name, value) {
        setValues(prevValues => ({ ...prevValues, [name]: value }));
    }

    async function handleRequestDemo(event) {
        event.preventDefault();
        const { errors: foundErrors, isValid } = validateRequestDemoForm(values);

        setErrors(foundErrors);

        if (isValid) {
            setIsLoading(true);

            const { error } = await postSlackMessage(getText(values));

            if (!error) {
                setIsLoading(false);
                setSuccessFormSubmission(true);
                setValues(initialValues);
            } else {
                setIsLoading(false);
                setErrorFormSubmission(true);
            }
        }
    }

    return (
        <Container narrow>
            <ContentContainer>
                <Row gutter={24}>
                    <Col xs={12} md={6}>
                        <Heading1 marginBottom="24px">{data.title}</Heading1>
                        {data.description ? (
                            <RichText
                                dangerouslySetInnerHTML={{
                                    __html: formatList(marked(data.description)),
                                }}
                            />
                        ) : null}
                    </Col>
                    <Col xs={12} md={6}>
                        <FormContainer>
                            <form onSubmit={handleRequestDemo}>
                                <Row gutter={24}>
                                    <Col xs={12} sm={6}>
                                        <FormInput
                                            label={data.form.firstName.label}
                                            id="first-name"
                                            name="firstName"
                                            placeholder={data.form.firstName.placeholder}
                                            onChange={handleOnChange}
                                            error={errors.firstName}
                                            value={values.firstName}
                                        />
                                    </Col>
                                    <Col xs={12} sm={6}>
                                        <FormInput
                                            label={data.form.LastName.label}
                                            id="last-name"
                                            name="lastName"
                                            placeholder={data.form.LastName.placeholder}
                                            onChange={handleOnChange}
                                            error={errors.lastName}
                                            value={values.lastName}
                                        />
                                    </Col>
                                </Row>
                                <FormInput
                                    label={data.form.BusinessEmail.label}
                                    id="business-email"
                                    name="businessEmail"
                                    placeholder={data.form.BusinessEmail.placeholder}
                                    onChange={handleOnChange}
                                    error={errors.businessEmail}
                                    value={values.businessEmail}
                                />
                                <FormInput
                                    label={data.form.companyName.label}
                                    id="company-name"
                                    name="companyName"
                                    placeholder={data.form.companyName.placeholder}
                                    onChange={handleOnChange}
                                    error={errors.companyName}
                                    value={values.companyName}
                                />
                                <FormInput
                                    label={data.form.phoneNumber.label}
                                    id="phone-number"
                                    name="phoneNumber"
                                    placeholder={data.form.phoneNumber.placeholder}
                                    onChange={handleOnChange}
                                    value={values.phoneNumber}
                                    error={errors.phoneNumber}
                                    type="number"
                                />
                                <FormInput
                                    label={data.form.country.label}
                                    placeholder={data.form.country.placeholder}
                                    value={values.country}
                                    name="country"
                                    id="country"
                                    options={data.form.country.values}
                                    error={errors.country}
                                    onChange={handleOnChange}
                                    type="text"
                                />
                                <TextArea
                                    label={data.form.interest.label}
                                    id="interest"
                                    name="interest"
                                    placeholder={data.form.interest.placeholder}
                                    onChange={handleOnChange}
                                    error={errors.interest}
                                    value={values.interest}
                                />
                                {/* If form submission is success do not show button anymore but the message */}
                                {successFormSubmission ? (
                                    <Message type="success" text={data.form.success_message} />
                                ) : (
                                    <Button
                                        width="100%"
                                        type="submit"
                                        isLoading={isLoading}
                                        label={data.form.submitButton.label}
                                    />
                                )}

                                {/* Show error message if submission failed */}
                                {errorFormSubmission && (
                                    <Message type="error" text={data.form.error_message} />
                                )}

                                {/* Linkedin tracking after successful form submit */}
                                {successFormSubmission && (
                                    <img
                                        height="1"
                                        width="1"
                                        style={{ display: 'none' }}
                                        alt="linkedin pixel"
                                        src="https://px.ads.linkedin.com/collect/?pid=3280945&conversionId=4192497&fmt=gif"
                                    />
                                )}
                            </form>
                        </FormContainer>
                    </Col>
                </Row>
            </ContentContainer>
            <iframe
                name="hiddenFrame"
                title="hiddenFrame"
                width="0"
                height="0"
                border="0"
                style={{ display: 'none' }}
            />
        </Container>
    );
};

RequestDemo.propTypes = {
    data: PropTypes.shape({
        description: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        form: PropTypes.shape({
            success_message: PropTypes.string,
            error_message: PropTypes.string,
            firstName: PropTypes.shape({
                label: PropTypes.string.isRequired,
                placeholder: PropTypes.string,
            }).isRequired,
            LastName: PropTypes.shape({
                label: PropTypes.string.isRequired,
                placeholder: PropTypes.string,
            }).isRequired,
            BusinessEmail: PropTypes.shape({
                label: PropTypes.string.isRequired,
                placeholder: PropTypes.string,
            }).isRequired,
            companyName: PropTypes.shape({
                label: PropTypes.string.isRequired,
                placeholder: PropTypes.string,
            }).isRequired,
            phoneNumber: PropTypes.shape({
                label: PropTypes.string.isRequired,
                placeholder: PropTypes.string,
            }).isRequired,
            country: PropTypes.shape({
                values: PropTypes.arrayOf(
                    PropTypes.shape({
                        value: PropTypes.string.isRequired,
                        label: PropTypes.string.isRequired,
                    })
                ).isRequired,
                label: PropTypes.string.isRequired,
                placeholder: PropTypes.string,
            }).isRequired,
            interest: PropTypes.shape({
                label: PropTypes.string.isRequired,
                placeholder: PropTypes.string,
            }).isRequired,
            submitButton: PropTypes.shape({
                label: PropTypes.string.isRequired,
            }).isRequired,
        }).isRequired,
    }).isRequired,
};

export default RequestDemo;
