import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

// components
import Layout from '../components/Layout';
import RequestDemo from '../components/Pages/RequestDemo';

// hooks
import useTranslated from '../hooks/useTranslated';

const RequestDemoComponent = ({ data, ...rest }) => {
    const { translatedData } = useTranslated(data, rest.pageContext);

    return (
        <Layout
            path={rest.location.pathname}
            lng={rest.pageContext.langKey}
            hasContainer={false}
            minimalHeader
            minimalFooter
            metatags={translatedData.strapiRequestDemo.metatags}
            href="/request-demo/"
        >
            <RequestDemo data={translatedData.strapiRequestDemo} lng={rest.pageContext.langKey} />
        </Layout>
    );
};

RequestDemoComponent.propTypes = {
    data: PropTypes.shape({
        strapiRequestDemo: PropTypes.shape({}).isRequired,
    }).isRequired,
};

export default RequestDemoComponent;

export const query = graphql`
    query RequestDemoDE {
        strapiRequestDemo {
            metatags {
                description_de
                description_en
                title_de
                title_en
            }
            form {
                BusinessEmail {
                    label_de
                    label_en
                    placeholder_en
                    placeholder_de
                }
                LastName {
                    placeholder_en
                    placeholder_de
                    label_en
                    label_de
                }
                companyName {
                    placeholder_en
                    placeholder_de
                    label_en
                    label_de
                }
                country {
                    placeholder_en
                    placeholder_de
                    label_en
                    label_de
                    values {
                        label_en
                        label_de
                        value
                    }
                }
                firstName {
                    placeholder_en
                    placeholder_de
                    label_en
                    label_de
                }
                interest {
                    placeholder_en
                    placeholder_de
                    label_en
                    label_de
                }
                phoneNumber {
                    placeholder_en
                    placeholder_de
                    label_en
                    label_de
                }
                submitButton {
                    label_en
                    label_de
                }
                success_message_en
                success_message_de
                error_message_en
                error_message_de
            }
            description_en
            description_de
            title_en
            title_de
        }
    }
`;
