import styled from 'styled-components';

export const ContentContainer = styled.div`
    margin: 80px 0;
    min-height: calc(100vh - 276px);

    @media all and (max-width: 991px) {
        margin: 3em 0;
    }
`;

export const FormContainer = styled.div`
    box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    padding: 24px 16px;
`;

export const RichText = styled.div`
    line-height: 32px;
    font-size: 1.0625rem;

    @media all and (max-width: 768px) {
        font-size: 0.875rem;
        line-height: 24px;
    }

    ul {
        margin-top: 24px;
        margin-bottom: 24px;
    }

    li {
        margin-left: 48px;
        line-height: 32px;
    }

    span {
        width: 24px;
        height: 24px;
        border-radius: 50%;
        position: absolute;

        &::before,
        &::after {
            content: '';
            position: absolute;
            background-color: #39d6bc;
            width: 2px;
        }

        &::before {
            top: 5px;
            left: 15px;
            height: 20px;
            transform: rotate(45deg);
        }
        &::after {
            top: 13px;
            left: 5px;
            height: 10px;
            transform: rotate(-45deg);
        }
    }
`;
